<template>
  <div class="levelUpModal">
    <div class="levelUpModal__icon">
      <svg viewBox="0 0 237 237" xmlns="http://www.w3.org/2000/svg"><defs><linearGradient id="a" x1="23.4148243%" y1="12.8570313%" x2="75.3159072%" y2="87.50625%"><stop stop-color="#FAE198" offset="0%" /><stop stop-color="#F2BF5F" offset="100%" /></linearGradient><linearGradient id="b" x1="23.4148243%" y1="12.8587076%" x2="75.3159072%" y2="87.5045573%"><stop stop-color="#FAE198" offset="0%" /><stop stop-color="#F2BF5F" offset="100%" /></linearGradient></defs><g transform="translate(-70 -100)" fill="none" fill-rule="evenodd"><path d="M188.5 100c65.445743 0 118.5 53.054257 118.5 118.5S253.945743 337 188.5 337 70 283.945743 70 218.5 123.054257 100 188.5 100zm1 7C127.92025 107 78 156.92025 78 218.5S127.92025 330 189.5 330 301 280.07975 301 218.5 251.07975 107 189.5 107z" fill="url(#a)" /><ellipse fill="url(#b)" cx="189.002369" cy="218" rx="105.00237" ry="105" /><path d="M193.118518 132.325565c.626493.449559 1.175256.998657 1.624541 1.625533l74.932184 104.550867c2.274743 3.173888 1.547411 7.592002-1.624541 9.868133-1.200747.861632-2.641187 1.325027-4.118786 1.325027l-37.950474-.000331-4.350017 68.136835C211.36077 321.186187 200.393394 323 189.002369 323c-11.126924 0-21.849617-1.730682-31.913561-4.937533l-4.365315-68.367673-38.655946.000331c-3.903299 0-7.067547-3.16618-7.067547-7.07186 0-1.478501.463112-2.91982 1.324219-4.1213l74.932184-104.550867c2.274743-3.173888 6.690162-3.901664 9.862115-1.625533z" fill="#FFF" opacity=".2"/></g></svg>

      <div
        v-bind:class="{
          'levelUpModal__iconNumber': true,
          'levelUpModal__iconNumber--small': member.fitlevel.value > 9,
        }"
      >
        {{ member.fitlevel.value }}
      </div>
    </div>

    <div class="levelUpModal__title">
      {{ $t('levelUpModal.title') }}
    </div>

    <p class="levelUpModal__body">
      {{ $t('levelUpModal.body') }}
    </p>

    <base-button
      v-bind:to="{ name: 'aboutyouProgress' }"
      modifiers="primary"
      v-on:click="$emit('close')"
    >
      {{ $t('levelUpModal.buttonLabel') }}
    </base-button>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import BaseButton from '@/components/BaseButton';
import confetti from '@/mixins/confetti';

export default {
  mixins: [
    confetti,
  ],

  components: {
    BaseButton,
  },

  computed: {
    ...mapState('member', ['member']),
  },

  mounted() {
    this.giveMeConfetti();
  },
};
</script>

<style lang="scss" scoped>
@import "@/scss/lib";

.levelUpModal {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  text-align: center;
  z-index: 0;
}

.levelUpModal__icon {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  margin: 0 0 rem(40px) 0;
  width: 75%;
  max-width: 200px;

  svg {
    position: relative;
    width: 100%;
    height: 100%;
  }

  &:before {
    content: url('data:image/svg+xml, <svg width="770" height="767" xmlns="http://www.w3.org/2000/svg"><path d="M529.7623 20.7055l-135.7374 352.86 1.5666-.2468-1.7272.6645-.5726 1.4895.2125-1.351-.9137.3517.016.101.0418-.1092-.0298.1842.394 2.5054L643.0088 70.8224l56.5685 56.5685-306.7112 250.3034.2908.2363 352.245-135.4993 20.7055 77.274-370.0927 58.3471 373.7775 58.9302-20.7056 77.274-353.782-136.0922-1.56.2467 305.2252 249.0921-56.5685 56.5686-249.357-305.5512-.8011.127L527.361 729.8963l-77.274 20.7056-58.3843-370.3133-62.6509 386.07-77.274-20.7055 139.2079-366.8073-.3509.0561.3864-.15.3105-.8186-.4414-2.8042.534 1.3883.3399.277.5323-1.402-.27-.702-1.8007.6923L152.6727 669.163 96.104 612.5945l292.6597-236.6498L40.8243 509.7894l-20.7055-77.274 370.2687-58.376L0 310.7874l20.7055-77.274 370.291 140.53.1483-.0237.4757-.3843-.6446-1.674L97.1957 134.4082l56.5685-56.5685 236.6497 292.6597L256.5693 22.5599l77.274-20.7055 58.4956 371.026.4274.5299-.3944-.32.1136.718 1.0953-.1734L452.4883 0l77.274 20.7055zm-137.1942 357.232l-.4545.371.008.0207.583-.2248-.1365-.167zm-.4032-.4943l-.114.7035.0492.127.3715-.4553-.3067-.3752zm.2736-1.6828l-.2273 1.3981.4794.3914.2956-.3628-.5477-1.4267zm-1.0008.7917l.2105.5477.0746-.1974-.285-.3503zm.1888-2.9007l-.2717.3353.3777-.0593-.106-.276z" fill="%23FFFCF4" fill-rule="evenodd"/></svg>');
    position: absolute;
    top: 50%;
    right: unset;
    left: 50%;
    transform: scale(2) translate(-25%, -25%);
    transform-origin: center center;
    animation: 60s linear 0s infinite levelUpRotate;
    z-index: -1;
  }
}

.levelUpModal__iconNumber {
  position: absolute;
  top: 50%;
  font-size: 140px;
  font-weight: 900;
  color: $color-white;
  transform: translateY(-50%);

  &--small {
    font-size: 110px;
  }
}

.levelUpModal__title {
  @include heading-3;
  margin: 0 0 rem(10px) 0;
}

.levelUpModal__body {
  @include paragraph;
  margin: 0 0 rem(20px) 0;
  max-width: 320px;
}

// .levelUpModal__button {
//   background: none;
//   border: 1px solid currentColor;
//   color: #f2bf5f;
// }

@keyframes levelUpRotate {
  from {
    transform: scale(2) translate(-25%, -25%) rotate(0deg);
  }
  to {
    transform: scale(2) translate(-25%, -25%) rotate(360deg);
  }
}
</style>
